@import 'styles/breakpoints';
@import 'styles/mixins';
@import 'styles/colors';

.root {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--block-padding);
    color: var(--colors-blue);

    &.flushTop {
        padding-top: 0;
    }

    &.flushBottom {
        padding-bottom: 0;
    }
}

.newsLetterInner {
    position: relative;
    width: 100%;
    max-width: var(--block-max-width);
    background-color: var(--colors-white);

    @include rounded-corners($overflow: hidden);
}

.columns {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.column {
    position: relative;
    width: 100%;
    height: 100%;

    &:last-child {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 3.6rem;
    }
}

.image {
    position: relative;
    width: 100%;
    max-width: none;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.imageWrap {
    position: relative;
    width: 100%;
    height: 100%;
    aspect-ratio: 1;
}

.lockup {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.headline {
    margin-top: 2rem;
    text-align: center;
    text-transform: uppercase;
}

.copy {
    max-width: 39.6rem;
    margin: 2rem 0 3rem;
    text-align: center;
}

.inputLockupWrap {
    position: relative;
    display: flex;
    width: 100%;
    max-width: 28.3rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.legal {
    margin-top: 2.4rem;
    text-align: center;

    > div {
        color: rgba(color('blue'), 0.25);
    }
}

.form {
    display: flex;
    width: 100%;
    max-width: 39.6rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.inputLockup {
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.inputWrap {
    width: 100%;
}

.input {
    width: 100%;
    min-height: 4.8rem;
    padding: 0 1.5rem;
    border: 0;
    border-radius: 0; // remove input element rounding on ios
    border-bottom: 0.1rem solid rgba(0, 30, 96, 40%);
    appearance: none;
    color: var(--colors-blue);
    text-align: center;

    &::placeholder {
        color: var(--colors-blue);
        font-weight: 500;
        opacity: 0.4;
    }

    .error & {
        border-bottom: 0.1rem solid var(--colors-cheeseSharpCheddar);
    }

    .inputSuccess & {
        border-bottom: 0.1rem solid var(--colors-cheeseMexican4Cheese);
        pointer-events: none;

        &::placeholder {
            color: var(--colors-cheeseMexican4Cheese);
            opacity: 1;
        }
    }
}

.submitButton {
    position: relative;
    margin-top: 2.4rem;

    .inputSuccess & {
        display: none;
    }
}

.joinError {
    width: 100%;
    padding: 1.2rem 0 0;
    color: var(--colors-cheeseSharpCheddar);
}

.joinErrorInner {
    position: absolute;
    width: 100%;
    text-align: center;
}

@include medium {
    .columns {
        height: 0;
        flex-direction: row-reverse;
        padding-bottom: 56%;
    }

    .column {
        position: absolute;
        top: 0;
        right: 0;
        width: 50%;

        &:last-child {
            left: 0;
        }

        .imageOnLeft & {
            left: 0;

            &:last-child {
                right: 0;
                left: auto;
            }
        }
    }

    .inputLockupWrap {
        max-width: 32.2rem;
    }

    .copy {
        margin: 1.2rem 0 0.6rem;
    }

    .image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

@include large {
    .columns {
        padding-bottom: 50%;
    }

    .copy {
        margin: 2rem 0 3.5rem;
    }
}
